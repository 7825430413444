import React, { InputHTMLAttributes, LabelHTMLAttributes } from "react"
import { useTranslation } from "react-i18next"
import { ActionTypes } from "../../../context/userReducers"
import { useTampuuriHoods } from "../../../hooks/useTampuuriHoods"
import { useUserDispatch } from "../../../hooks/useUserState"
import { formDataToObj } from "../../../utils"
import * as styles from "./ApartmentSearchForm.module.scss"

type ApartmentSearchFormProps = {
  // onSearchUpdate: (searchState: ApartmentSearchState) => void
  params: ApartmentSearchParameters
}

type CheckboxProps = {
  name: string
  label: string
  id?: string
  value?: string
  required?: boolean
  checked: boolean
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>
}

const Checkbox = (props: CheckboxProps) => {
  const [checked, setChecked] = React.useState(props.checked)
  const id = props.id || `${props.name}-${props.value}`
  return (
    <div>
      <input
        id={id}
        type="checkbox"
        name={props.name}
        value={props.value || 1}
        required={props.required}
        {...props.inputProps}
        checked={checked}
        onChange={() => setChecked(!checked)}
      />
      <label htmlFor={id} {...props.labelProps}>
        {props.label}
      </label>
    </div>
  )
}

function hasValue(param: string | string[] | undefined, needle: string): boolean {
  if (Array.isArray(param)) {
    return param.includes(needle)
  }
  return param === needle
}

const ApartmentSearchForm = (props: ApartmentSearchFormProps) => {
  const dispatch = useUserDispatch()
  const hoods = useTampuuriHoods()
  const { t } = useTranslation("apartmentSearchForm")

  // console.debug("Rendering ApartmentSearchForm", props)

  const onSubmit = (e: React.FormEvent): void => {
    e.preventDefault()

    // tee lomakkeen arvoista FormData instanssi helpompaa käsittelyä varten
    const formData = new FormData(e.target as HTMLFormElement)

    // tee JS objecti UserStatea varten, jotta lomakkeen valinnat jää talteen yli sivunvaihtojen
    const params = formDataToObj(formData)

    // console.debug("ApartmentSearchForm onSubmit: Kirjoita hakuparametrit käyttäjän tilaan", params)

    // päivitä UserState
    dispatch({ type: ActionTypes.SetApartmentSearchParams, payload: params })
  }

  const onReset = (e: React.FormEvent): void => {
    e.preventDefault()

    // console.debug("ApartmentSearchForm onReset: Resetoi hakuparametrit käyttäjän tilassa")

    dispatch({ type: ActionTypes.ResetApartmentSearch })
  }

  const { params } = props

  return (
    <form onSubmit={onSubmit} onReset={onReset} className={styles.container}>
      <button type="reset" className={`${styles.reset} link-like`}>
        &#9447; {t("emptyForm", "Tyhjennä hakuvalinnat")}
      </button>
      <div className={styles.inputs}>
        <fieldset>
          <legend>{t("housingType", "Asumismuoto")}</legend>
          <Checkbox
            id="housingType-rental"
            name="housingType"
            value="rental"
            label={t("rental", "Vuokra-asunto")}
            checked={hasValue(params.housingType, "rental")}
          />
          <Checkbox
            id="housingType-student"
            name="housingType"
            value="student"
            label={t("student", "Opiskelija-asunto")}
            checked={hasValue(params.housingType, "student")}
          />
          <Checkbox
            id="housingType-senior"
            name="housingType"
            value="senior"
            label={t("senior", "Senioriasunto")}
            checked={hasValue(params.housingType, "senior")}
          />
        </fieldset>
        <fieldset>
          <legend>{t("location", "Sijainti")}</legend>
          <label htmlFor="address">{t("addressOrZip", "Osoite tai postinumero")}</label>
          <input id="address" type="text" name="address" defaultValue={params.address} />
          <label htmlFor="hood">{t("neighborhood", "Asuinalue")}</label>
          <select id="hood" name="hood" defaultValue={params.hood}>
            <option value="">{t("noChoice", "Ei väliä")}</option>
            {hoods.map((hood, i) => (
              <option key={i} value={hood}>
                {hood}
              </option>
            ))}
          </select>
        </fieldset>
        <fieldset>
          <legend>{t("rentAndSize", "Vuokra ja asunnon koko")}</legend>
          <fieldset>
            <legend>{t("rentPerMonth", "Vuokra / kk")}</legend>
            <label htmlFor="rentMax" className="sr-only">
              {t("rentMax", "Kuukasivuokra enintään")}
            </label>
            <input
              id="rentMax"
              type="number"
              min="230"
              max="1200"
              step="5"
              name="rentMax"
              defaultValue={params.rentMax || ""}
              placeholder={"max €/kk"}
            />
          </fieldset>
          <fieldset>
            <legend>{t("apartmentSize", "Asuinpinta-ala")}</legend>
            <label htmlFor="sizeMin" className="sr-only">
              {t("sizeMin", "Asunnon pinta-ala vähintään")}
            </label>
            <input
              id="sizeMin"
              type="number"
              min="10"
              max="150"
              step="1"
              name="sizeMin"
              defaultValue={params.sizeMin}
              placeholder={"min m²"}
            />
            <label htmlFor="sizeMax" className="sr-only">
              {t("sizeMax", "Asunnon pinta-ala enintään")}
            </label>
            <input
              id="sizeMax"
              type="number"
              min="30"
              max="160"
              step="1"
              name="sizeMax"
              defaultValue={params.sizeMax}
              placeholder={"max m²"}
            />
          </fieldset>
        </fieldset>
        <fieldset>
          <legend>{t("buildingType", "Talotyyppi")}</legend>
          <Checkbox
            id="buildingType-apartment"
            name="buildingType"
            value="apartment"
            label={t("kerrostalo", "Kerrostalo")}
            checked={hasValue(params.buildingType, "apartment")}
          />
          <Checkbox
            id="buildingType-row"
            name="buildingType"
            value="row"
            label={t("rivitalo", "Rivitalo")}
            checked={hasValue(params.buildingType, "row")}
          />
          {/* <Checkbox
            name="buildingType"
            value="wooden"
            label={t("puutalo", "Puutalo")}
            checked={hasValue(params.buildingType, "wooden")}
          />
          <Checkbox
            name="buildingType"
            value="semidetached"
            label={t("paritalo", "Paritalo")}
            checked={hasValue(params.buildingType, "semidetached")}
          />
          <Checkbox
            name="buildingType"
            value="balconyaccess"
            label={t("luhtitalo", "Luhtitalo")}
            checked={hasValue(params.buildingType, "balconyaccess")}
          /> */}
        </fieldset>
        <fieldset>
          <legend>{t("apartmentType", "Huoneita")}</legend>
          <Checkbox
            id="apartmentType-one"
            name="apartmentType"
            value="one"
            label={t("1h", "1 h")}
            checked={hasValue(params.apartmentType, "one")}
          />
          <Checkbox
            id="apartmentType-two"
            name="apartmentType"
            value="two"
            label={t("2h", "2 h")}
            checked={hasValue(params.apartmentType, "two")}
          />
          <Checkbox
            id="apartmentType-three"
            name="apartmentType"
            value="three"
            label={t("3h", "3 h")}
            checked={hasValue(params.apartmentType, "three")}
          />
          <Checkbox
            id="apartmentType-fourplus"
            name="apartmentType"
            value="fourplus"
            label={t("4h", "4+ h")}
            checked={hasValue(params.apartmentType, "fourplus")}
          />
          <Checkbox
            id="apartmentType-other"
            name="apartmentType"
            value="other"
            label={t("muut", "Muut")}
            checked={hasValue(params.apartmentType, "other")}
          />
        </fieldset>
      </div>
      <hr />
      <div className={styles.controls}>
        <Checkbox
          id="free"
          name="free"
          value="1"
          checked={!!params.free}
          label={t("showOnlyVacant", "Näytä vain vapaat asunnot")}
        />
        <button type="submit">{t("search", "Hae")}</button>
      </div>
    </form>
  )
}

export default ApartmentSearchForm
