import { graphql } from "gatsby"
import React from "react"
import AttentionBulletins from "../dato/AttentionBulletins"
import FrontPageContent from "../dato/FrontPageContent"
import HeroImage from "../organisms/HeroImage"

export type FrontPageProps = Queries.DatoFrontPageFragment

const FrontPage = (props: FrontPageProps) => {
  // console.debug("Rendering template FrontPage", props)
  const { content, title, ingress, image, mobileImage } = props
  if (!content || !title) {
    console.warn("Invalid props for FrontPage")
    return null
  }

  return (
    <>
      <HeroImage image={image} mobileImage={mobileImage} />
      <AttentionBulletins />
      <h1>{title}</h1>
      {ingress && <p className="ingress">{ingress}</p>}
      <FrontPageContent content={props.content} />
    </>
  )
}

export default FrontPage

export const query = graphql`
  fragment DatoFrontPage on DatoCmsFrontpage {
    title
    ingress
    image {
      ...DatoHeroImage
    }
    mobileImage {
      ...DatoHeroMobileImage
    }
    ...DatoFrontpageContent
  }
`
